import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/codebuild/output/src1331782344/src/tyto-www/src/templates/MarkdownPage.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tyto Email Disclaimer`}</h1>
    <h2>{`Tyto's Privacy Policy`}</h2>
    <p>{`Company: IRJ Software, trading as Tyto
Physical address: Unit 4, 8 Osborn Lane, Bedfordview, 2008, Gauteng, South Africa
Postal address: Postnet Suite 423, Private Bag X19, Gardenview, 2047, South Africa
Contact Details: SA Sharecall: 0860 000 626, International: +27 11 856-2000
Website: `}<a parentName="p" {...{
        "href": "http://www.tyto.me"
      }}>{`www.tyto.me`}</a></p>
    <h2>{`Confidentiality`}</h2>
    <p>{`The information contained in or attached to this email, instant message, peer to peer traffic and network traffic (collectively referred to as communication/s) may contain confidential material, and is intended only for the person to which it is addressed.`}</p>
    <p>{`IRJ Software also does not represent, guarantee or warrant that this communication was sent for and on behalf of IRJ Software. In the case of any doubt with respect to any of the foregoing please obtain written notification from the sender of this communication that it was intended for you, that the information contained therein is correct and accurate, and that the communication was intended to be sent for and on behalf of IRJ Software.`}</p>
    <p>{`If you receive this communication and it was not intended for you, and without derogating from, or limiting the scope of, any of the foregoing (including but not limited to the disclaimers), it would be appreciated if you would delete same and desist from using same in any manner.`}</p>
    <h2>{`Prohibited Use`}</h2>
    <p>{`IRJ Software prohibits any unsolicited or bulk communication transmission to be sent, or caused to be sent, over the Internet, to any IRJ Software address. You may not harvest or collect addresses for the purpose of sending unsolicited or bulk communications.`}</p>
    <p>{`IRJ Software prohibits any communication to be sent, or caused to be sent, to any IRJ Software address if the communication is intended to, or appears intended to, harass, embarrass, threaten, defame or impersonate any person, or if the communication is pornographic, offensive, indecent, discriminatory or offensive on the basis of sex, colour, national origin, race, age, religion, sexual orientation or disability.`}</p>
    <p>{`IRJ Software prohibits any communication to be sent, or caused to be sent, to any IRJ Software address that makes use of a forged header or deceptive addressing, or which attempts in any manner to disguise the source of the communication.`}</p>
    <p>{`You may not use IRJ Software or its contents in any way that could overburden, impair, disable or damage any IRJ Software server, or the networks connected to any of IRJ Software servers.`}</p>
    <p>{`You may not attempt to gain unauthorized access (through hacking, brute force, and password mining or any other means) to any services, accounts, computer systems, or networks connected to any IRJ Software server. You may not obtain or attempt to obtain any materials or information that has not been made intentionally available by IRJ Software.`}</p>
    <h2>{`Limitation of Liability`}</h2>
    <p>{`Although IRJ Software endeavours to maintain a computer virus, spyware, malicious program (collectively known a malware) free network, IRJ Software does not warrant that this transmission is malware-free and will not be liable for any damages whatsoever resulting from any malware whatsoever transmitted.`}</p>
    <p>{`IRJ Software will under no circumstances be liable for any direct, indirect, consequential damages and/or expenses or any loss of profits of whatsoever nature, as a result of, but not limited to, corrupted data, loss of data or non-functionality of information systems, even if the sender of this communication has been advised of the possibility.`}</p>
    <p>{`IRJ Software, disclaims liability for any loss, damage or expense however caused, arising from the sending, receipt, or use of this e-mail and on any reliance placed upon the information provided through this service and does not guarantee the completeness or accuracy of the information.`}</p>
    <p>{`The views and opinions of the individuals expressed in this communication may not reflect the views and opinion of IRJ Software or its directors. The purpose of the facility at IRJ Software is to convey official IRJ Software communications. IRJ Software will not be liable for any content, i.e. malicious code, opinions or views where the facility was utilised outside the scope as explained above or company policy.`}</p>
    <p>{`The IRJ Software website Terms & Conditions must also be taken into account.`}</p>
    <h2>{`Monitoring`}</h2>
    <p>{`IRJ Software has a duty to manage and retain certain records and mitigate possible risks, i.e. (not limited to) ensure that IRJ Software operates in an environment free of malware and therefore reserves the right to intercept, monitor, copy (retain) or block communications to and from IRJ Software. Should you respond to this communication, you consent that your communication will be subject to IRJ Software filtering, scanning, monitoring and blocking procedures.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      